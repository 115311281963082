import React, { useEffect, useRef } from "react";
import * as styles from "./ExperienceSlider.module.scss";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { gsap } from "gsap";

const data = [
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/fastest_growing_app_development_company_2021_58dd350b2d.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/vr_agencies_c74d51d4d1.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_software_developers_2020_8bccf52025.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_web_development_companies_7a77af654f.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_software_development_companies_409b1f56c7.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_mobile_app_developers_2021_27b906093c.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_web_development_company_0ce429afcb.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/featured_on_upcity_a631ceabf1.png",
  },
  {
    logos:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/top_ecommerce_development_company_b1062697c7.png",
  },
]

const TrustInvoZone = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const items = slider.children;
    const totalItems = items.length;
    const itemWidth = items[0].offsetWidth;
    const totalWidth = totalItems * itemWidth;

    // Initialize GSAP animation
    const tl = gsap.timeline({ repeat: -1, defaults: { ease: "none" } });

    // Move slider to the left and then reset position
    tl.fromTo(
      slider,
      { x: 0 },
      {
        x: `-${totalWidth}`,
        duration: 40, // Adjust duration for desired speed
        ease: "linear",
        modifiers: {
          x: gsap.utils.unitize((x) => parseFloat(x) % totalWidth),
        },
      }
    );

    return () => {
      tl.kill(); // Cleanup GSAP timeline on component unmount
    };
  }, []);

  return (
    <div className={styles.Trust}>
      <Container>
        <Row className="align-items-center">
          <Col lg={12} md={12} sm={12} xs={12}>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderWrapper} ref={sliderRef}>
                {data.map((e, i) => (
                  <div key={i} className={styles.portfoliosLogos}>
                    <img
                      src={e?.logos}
                      alt="client logos"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                ))}
                {/* Repeat the logos to create a seamless loop effect */}
                {data.map((e, i) => (
                  <div key={`repeat-${i}`} className={styles.portfoliosLogos}>
                    <img
                      src={e?.logos}
                      alt="client logos"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrustInvoZone;
