import React from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import Row from "react-bootstrap/Row"
import * as styles from "./Banner.module.scss"

const TypingEffect = React.lazy(() => import("./TypingEffect"))

const Banner = ({ strapiData }) => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true)
    }, 10000)
    return () => clearTimeout(timer)
  }, [])

  return (
    <div className={styles.portfolioAppWorkBanner}>
      <Container className={styles.bannerCon}>
        <div className={styles.fintech}>
          <video
            className={styles.backgroundVideo}
            autoPlay
            muted
            loop
            poster="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_07_22_233957_b57448a23f.webp"
          >
            <source
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Home_Page_Video_Final_Format_8fe0b0e15f.mp4"
              type="video/mp4"
            />
          </video>
          <Container>
            <Row className="align-items-center justify-content-center">
              <Col lg={10}>
                <div className={styles.Heading}>
                  <p className={styles.ser}>Software Development Consulting</p>
                  {/* <h1 className={styles.bannerHeading}>
                    Strategy, Design, Engineering, Build & Execution For
                    {!show ? (
                      <span className={styles.inlineText}> Innovators</span>
                    ) : (
                      <span className={styles.inlineText}>
                        <TypingEffect />
                      </span>
                    )}
                  </h1> */}
                  <h1 className={styles.bannerHeading}>
                    Innovate. Accelerate. Execute.
                  </h1>
                  <p className={styles.dec}>
                    Driving growth & excellence for startups, enterprises &
                    Innovators.
                  </p>
                </div>
                <Link to={"/contact-us/"} className={styles.btn}>
                  Book a 15 min call
                  <img
                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                    decoding="async"
                    loading="lazy"
                    alt="explore icon"
                  />
                </Link>
              </Col>
              <Col lg={2}></Col>
            </Row>
          </Container>
        </div>
      </Container>
    </div>
  )
}

export default Banner
