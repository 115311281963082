// extracted by mini-css-extract-plugin
export var backing = "Backing2-module--backing--9634f";
export var blueText = "Backing2-module--blueText--a297d";
export var btn = "Backing2-module--btn--e6e03";
export var card1 = "Backing2-module--card1--d2378";
export var card2 = "Backing2-module--card2--ac599";
export var card3 = "Backing2-module--card3--965c9";
export var check = "Backing2-module--check--ed90a";
export var heading = "Backing2-module--heading--34d6f";
export var horizontalscrollcards = "Backing2-module--horizontalscrollcards--02113";
export var mainHeading = "Backing2-module--mainHeading--9a314";
export var ser = "Backing2-module--ser--8e49d";
export var skills = "Backing2-module--skills--dab60";
export var subheading = "Backing2-module--subheading--f38a8";