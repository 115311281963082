import React, { useContext } from "react"
import Accordion from "react-bootstrap/Accordion"
import AccordionContext from "react-bootstrap/AccordionContext"
import { useAccordionToggle } from "react-bootstrap/AccordionToggle"
import Card from "react-bootstrap/Card"
import { Link } from "gatsby"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./ProgressMobile.module.scss"
import "./ProgressMobile.scss"

const data = [
  {
    name: "Fintech",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19487_cbf2b9ee11.webp",
    button: "/fintech/",
    button2: "Explore More in Fintech",
    skills: [
      "Accounting information systems",
      "Block-chain based fintech apps",
      "Financial applications development",
      "AI-enabled fintech applications",
    ],
  },
  {
    name: "E-commerce",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19634_ef0a13e23c.webp",
    button: "/e-commerce/",
    button2: "Explore More in E-commerce",
    skills: [
      "Magneto e-commerce applications",
      "B2B e-commerce development",
      "Shopify e-commerce development",
      "IoT-powered e-commerce operations",
      "Product information management ",
    ],
  },
  {
    name: "Healthcare",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19635_f2eafb162d.webp",
    button: "/healthcare/",
    button2: "Explore More in Healthcare",
    skills: [
      "Healthcare application development",
      "Telemedicine software development",
      "IoT healthcare software",
      "Health tracking applications",
      "EMR/EHR software development",
    ],
  },
  {
    name: "Food & Grocery",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19636_7913c80bd8.webp",
    button: "/food-groceries/",
    button2: "Explore More in Food & Grocery",
    skills: [
      "Food delivery application development",
      "Grocery store software",
      "Online food ordering application",
      "Restaurant delivery application",
      "Restaurant management software",
    ],
  },
  {
    name: "Education",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19637_923ba69ec7.webp",
    button: "/education/",
    button2: "Explore More in Education",
    skills: [
      "Learning management systems",
      "e-Learning application development",
      "Educational platform development",
      "Software for corporate training",
      "Virtual reality educational software",
    ],
  },
  {
    name: "Travel & Tourism",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19638_811695e78e.webp",
    button: "/travel-tourism/",
    button2: "Explore More in Travel & Tourism",
    skills: [
      "On-demand travel applications",
      "AR and VR-based travel applications",
      "E-booking and ticketing systems",
      "Transportation information applications",
      "Custom travel ERP solutions",
    ],
  },
  {
    name: "Real Estate",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19639_71e26c75f1.webp",
    button: "/contact-us/",
    button2: "Explore More in Estate",
    skills: [
      "Real estate CRM development ",
      "Cloud-based CRM",
      "Location-based services",
      "Salesforce automation services",
      "Automation of mundane tasks",
    ],
  },
  {
    name: "On-demand",
    image1:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19640_5e8501167f.webp",
    button: "/on-demand-services/",
    button2: "Explore More in On-demand",
    skills: [
      "AI-fueled solutions",
      "Push notification systems",
      "Advanced Booking systems",
      "Easy Payment integrations",
      "Effective customer care",
    ],
  },
]

const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const currentEventKey = useContext(AccordionContext)
  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey

  return (
    <Accordion.Toggle
      as={Card.Header}
      onClick={decoratedOnClick}
      className={isCurrentEventKey ? styles.currentCard : ""}
    >
      <div>{children}</div>
    </Accordion.Toggle>
  )
}

const Technology2 = () => {
  return (
    <section className={styles.section}>
      <Container>
        <p className={styles.ser}>Industries</p>
        <h2 className={styles.heading}>
          Our Software Development Expertise Spans Across Multiple Domains
        </h2>
        <Row className="justify-content-center gap-30">
          {data.map((e, i) => (
            <Col xl={6} lg={6} md={6} key={i}>
              <div className="modelsProgress">
                <Accordion>
                  <Card border="light" style={{ overflow: "visible" }}>
                    <ContextAwareToggle eventKey={`ek-${i}`}>
                      <div className={styles.faqsNum}>
                        <h3>{e.name}</h3>
                      </div>
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey={`ek-${i}`}>
                      <Card.Body className={styles.textColor}>
                        <div className={styles.cardWrapper}>
                          {e.skills.map((skill, eIdx) => (
                            <div className={styles.arrowPoint} key={eIdx}>
                              <img
                                src="https://invozone-backend.s3.us-east-1.amazonaws.com/dot_a41563b685.svg"
                                decoding="async"
                                loading="lazy"
                                alt="Arrow"
                              />
                              <p className={styles.skill}>{skill}</p>
                            </div>
                          ))}

                          <Link to="/" className={styles.btn}>
                            {e?.button2}
                          </Link>
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19635_f2eafb162d.webp"
                            alt="Placeholder"
                          />
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Technology2
