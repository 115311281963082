// extracted by mini-css-extract-plugin
export var active = "Progress-module--active--68f8c";
export var arrow = "Progress-module--arrow--62e81";
export var arrowPoint = "Progress-module--arrowPoint--e987d";
export var btn = "Progress-module--btn--f1284";
export var dec = "Progress-module--dec--20976";
export var heading = "Progress-module--heading--0deb2";
export var idusCard = "Progress-module--idusCard--b726c";
export var imgContainer = "Progress-module--imgContainer--8ae32";
export var indDec = "Progress-module--indDec--82038";
export var industriesImg = "Progress-module--industriesImg--3d994";
export var infoContainer = "Progress-module--infoContainer--569aa";
export var points = "Progress-module--points--2d049";
export var section = "Progress-module--section--c7570";
export var ser = "Progress-module--ser--819fb";
export var storyCard = "Progress-module--storyCard--12dd0";
export var storyCardWrapper = "Progress-module--storyCardWrapper--d40c4";
export var subHeading = "Progress-module--subHeading--c5bab";