import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import * as styles from "./Concerns.module.scss"

function Concerns() {
  return (
    <div className={styles.concerns}>
      <Container>
        <h2>Got An Idea Or Concerns? Let’s Discuss.</h2>
        <div className={styles.talkBtn}>
          <Link to={"/contact-us/"} className={styles.btn}>
            Talk To An Ace
            <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
            />
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default Concerns
