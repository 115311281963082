import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Stories.module.scss"
import { Link } from "gatsby"

export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Product Engineering",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/product_eng_50fd19a33d.svg",
      description:
        "Our Product Engineering Services cover product management, design, development, DevOps, testing, security & more.",
      button: "/contact-us/",
      skills: [
        "Discovery workshop",
        "Custom software development",
        "Web app development",
        "Mobile app development",
      ],
    },
    {
      name: "Data & Artificial Intelligence",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/data_and_artificial_intelligence_16416b2b5f.svg",
      description:
        "Keeping ourselves in the loop with the latest AI trends, we help businesses use data & AI to make informed decisions.",
      button: "/ai-development-services/",
      skills: [
        "AI strategy and consulting",
        "AI model deployment and integration",
        "Natural language processing",
        "Machine learning model development",
      ],
    },
    {
      name: "Digital Consulting",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/digital_consulting_f61886ecba.svg",
      description:
        "Guiding businesses all way through the digital transformation with strategic insights, expert advice & latest IT trends.",
      button: "/contact-us/",
      skills: [
        "Enterprise agile transformation",
        "Digital transformation",
        "Application re-engineering",
        "Application modernization",
      ],
    },
    {
      name: "DevOps & CloudOps",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/devops_and_cloudops_b4efea0de1.svg",
      description:
        "We optimize operations with advanced DevOps and CloudOps strategies while boosting agility & scalability in technology deployments.",
      button: "/devops/",
      skills: [
        "CI/CD as a service",
        "Infrastructure as code",
        "Security and Compliance",
        "Monitoring and logging",
      ],
    },
    {
      name: "Emerging Technologies",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/emerging_technologies_75e9e07d90.svg",
      description:
        "We’ve plunged ourselves into the emerging IT trends, therefore, you can trust us with anything that’s new in the market. ",
      button: "/contact-us/",
      skills: ["Metaverse", "AR/VR", "Apple vision pro", "Cyber security"],
    },
    {
      name: "Blockchain & Web 3.0",
      avatar:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/blockchain_and_web_3_0_2bbbb53c97.svg",
      description:
        "Building on blockchain technology, we develop secure, decentralized applications that redefine digital trust & efficiency.",
      button: "/contact-us/",
      skills: [
        "DApp Development",
        "Smart Contract Deployment",
        "Blockchain Consulting",
        "Tokenization Solutions",
      ],
    },
  ]

  const [activeStory, setActiveStory] = useState(data[0])

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={styles.section}>
        <Container>
          <p className={styles.ser}>Services</p>
          <h2 className={styles.heading}>
            Software Development Services For Forward Planners
          </h2>
          <Row>
            <Col xs={12} lg={6} className={styles.storyCardWrapper}>
              <Row className="gap-30">
                {data?.map(el => (
                  <Col xl={4} lg={6} md={6} className="gap-30">
                    <div key={`story_${el.name}`}>
                      <div
                        className={`${styles.storyCard} ${
                          el.name === activeStory.name && styles.active
                        }`}
                        onClick={() => setActiveStory(el)}
                      >
                        <div className={styles.imgContainer}>
                          <img
                            src={el?.avatar}
                            alt={el?.name}
                            decoding="async"
                            loading="lazy"
                            className={styles.serImg}
                          />
                        </div>
                        <div className={styles.infoContainer}>
                          <p>{el?.name}</p>
                          <p className={styles.role}>{el?.role}</p>
                        </div>
                      </div>
                    </div>
                    {el.name === activeStory.name && (
                      <div
                        className={`${styles.columnTopBannerImg}  d-sm-block d-md-none`}
                      >
                        {data?.map(el => (
                          <div>
                            {el.name === activeStory.name && (
                              <div className={styles.subHeading}>
                                <p className={styles.plannerSub}>{el?.name}</p>
                                <p className={styles.dec}>{el?.description}</p>
                                <div className={styles.points}>
                                  {el.skills &&
                                    el.skills.map((skill, eIdx) => (
                                      <div className={styles.arrowPoint}>
                                        <img
                                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_c1a0254ee7.svg"
                                          decoding="async"
                                          loading="lazy"
                                        />
                                        <p className={`${styles.skill}`}>
                                          {skill}
                                        </p>
                                      </div>
                                    ))}
                                </div>
                                <Link to={el?.button} className={styles.btn}>
                                  See All Services
                                  <img
                                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                </Link>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xs={12} lg={6}>
              <div
                className={`${styles.columnTopBannerImg2} d-md-block d-sm-none `}
              >
                {data?.map(el => (
                  <div>
                    {el.name === activeStory.name && (
                      <div className={styles.subHeading}>
                        <h3 className={styles.subHeading2}>{el?.name}</h3>
                        <p className={styles.dec}>{el?.description}</p>
                        <div className={styles.points}>
                          {el.skills &&
                            el.skills.map((skill, eIdx) => (
                              <div className={styles.arrowPoint}>
                                <img
                                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_c1a0254ee7.svg"
                                  decoding="async"
                                  loading="lazy"
                                />
                                <p className={`${styles.skill}`}>{skill}</p>
                              </div>
                            ))}
                        </div>
                        <Link to={el?.button} className={styles.btn}>
                          See All Services
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                            decoding="async"
                            loading="lazy"
                          />
                        </Link>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}
