import React, { useState } from "react"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import { Link } from "gatsby"
import Tab from "react-bootstrap/Tab"
import "./Models.scss"
import * as styles from "./Models.module.scss"

const data = [
  {
    title: "Product Development",
    dec: "Transforming your ideas into ready-to-launch products.",
    buttons: "/hire-product-manager/",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infographic_02_a11ca74172.webm",
    tech: [
      {
        name: "Early Growth Stage or Stealth Startup.",
      },
      {
        name: "Product Development Roadmap",
      },
      {
        name: "MVP Development",
      },
      {
        name: "Prototypes / POC",
      },
    ],
  },
  {
    title: "Managed IT Systems",
    dec: "Streamlined IT management for smooth operations.",
    buttons: "/contact-us/",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infograpgic_03_2_4a215787c0.webm",
    tech: [
      {
        name: "Scale and ongoing or live products.",
      },
      {
        name: "Refining Product Roadmaps for scalability.",
      },
      {
        name: "Completing Backlogs or Features",
      },
      {
        name: "Defined Technology Stacks",
      },
    ],
  },
  {
    title: "Team Augmentation",
    dec: "Upgrade your team with specialized skills & expertise.",
    buttons: "/staff-augmentation-company/",
    gifImage:
      "https://invozone-backend.s3.us-east-1.amazonaws.com/Infographic_1_adaa63c04a.webm",
    tech: [
      {
        name: "Operational team efficiency is high and nimble.",
      },
      {
        name: "Quick Turn-around times",
      },
      {
        name: "Access to the global talent pool",
      },
      {
        name: "Collaborate with in-house & Outsourced Teams",
      },
    ],
  },
]

const AngularTechStacks = ({ strapiData }) => {
  const [activeTab, setActiveTab] = useState(data[0]?.title)

  const handleTabClick = title => {
    setActiveTab(title)
  }

  return (
    <div className="modelsTab">
      <div className={styles.TechStacksWebHireAngular}>
        <Container>
          <p className={styles.ser}>Models</p>
          <h2 className={styles.heading}>Engagement models</h2>
          <div className={styles.cardsContentAngular}>
            <div>
              <Tab.Container id="left-tabs-example" activeKey={activeTab}>
                <Tab.Content className="homeTabs">
                  {data &&
                    data.map((e, i) => (
                      <Tab.Pane
                        eventKey={e.title}
                        key={i}
                        className={activeTab === e.title ? "active" : ""}
                      >
                        <Row className="align-items-center">
                          <Col lg={6}>
                            <div className={styles.subHeading}>{e?.title}</div>
                            <div className={styles.dec}>{e?.dec}</div>
                            <div className={styles.techIcon}>
                              {e?.tech &&
                                e?.tech.map((el, i) => (
                                  <div
                                    className={styles.technologyIcon}
                                    key={i}
                                  >
                                    <div className={styles.techImg}>
                                      <img
                                        decoding="async"
                                        loading="lazy"
                                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8e20c0afd7.png"
                                        alt="check"
                                      />
                                    </div>
                                    <p className={styles.engHeading}>
                                      {el?.name}
                                    </p>
                                  </div>
                                ))}
                            </div>
                            <div className={styles.talkBtn}>
                              <Link className={styles.btn}>
                                View Details
                                <img
                                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                                  decoding="async"
                                  loading="lazy"
                                  alt="explore icon"
                                />
                              </Link>
                            </div>
                          </Col>
                          <Col lg={6}>
                            {/* <img
                              src={e?.gifImage}
                              // src="https://invozone-backend.s3.us-east-1.amazonaws.com/Infographic_1_4c5d056a8d.webm"
                              alt="description image"
                              decoding="async"
                              loading="lazy"
                            /> */}
                            <video
                              className={styles.backgroundVideo}
                              autoPlay
                              muted
                              loop
                              // poster="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_07_22_233957_b57448a23f.webp"
                            >
                              <source
                                src={e?.gifImage}
                                type="video/webm"
                              />
                            </video>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    ))}
                </Tab.Content>
              </Tab.Container>
            </div>
            <div className={styles.fixedTabs}>
              <Nav variant="pills" className={`modelsTab`}>
                {data &&
                  data.map((e, i) => (
                    <Nav.Item key={i} className="cards">
                      <div
                        eventKey={`${e?.title}`}
                        className={`tabLink ${
                          activeTab === e.title ? "active" : ""
                        }`}
                        onClick={() => handleTabClick(e.title)}
                      >
                        <div className={styles.tabData}>
                          <p dangerouslySetInnerHTML={{ __html: e?.title }} />
                        </div>
                      </div>
                    </Nav.Item>
                  ))}
              </Nav>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default AngularTechStacks
