import React, { useEffect, useRef, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.scss"
import { Link } from "gatsby"
import "slick-carousel/slick/slick.scss"
import * as styles from "./BackingMobile.module.scss"
import "./BackingMobile.scss"

const Boost = ({ strapiData }) => {
  const slide1 = useRef(null)
  const [current, setCurrent] = useState(0)
  const [current_1, setCurrent_1] = useState(0)
  const [current_2, setCurrent_2] = useState(0)

  useEffect(() => {
    const isLastSlide =
      current === strapiData?.cards?.length - 3 ||
      current_1 === strapiData?.cards?.length - 2 ||
      current_2 === strapiData?.cards?.length - 1

    if (isLastSlide) {
      const timeout = setTimeout(() => {
        slide1?.current?.slickGoTo(0)
      }, 3000)

      return () => clearTimeout(timeout) // Cleanup on unmount or before running the effect again
    }
  }, [current, current_1, current_2, strapiData])

  const settings = {
    autoplay: true,
    dots: true,
    dotsClass: "backingDots",
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    speed: 200,
    beforeChange: (current, next) => setCurrent(next),
    arrows: false,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          beforeChange: (current, next) => setCurrent_1(next),
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          beforeChange: (current, next) => setCurrent_2(next),
        },
      },
    ],
  }

  const data = [
    {
      heading: "Remarkable ideas need the right team for execution",
      subheading: "I’m A StartUp",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19625_1_759b13dbad.webp",
      skills: [
        "Validate your idea",
        "Product Development Roadmap",
        "Prototyping",
        "Launch MVP in 3 months",
      ],
    },
    {
      heading: "Get scalable enterprise solutions for your business",
      subheading: "I'm an Enterprise",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19629_4b5e7c6833.webp",
      skills: [
        "Digital Transformation",
        "Application & System Reengineering",
        "Enterprise Mobility",
        "Enterprise App Development",
      ],
    },
    {
      heading: "Fixing buggy softwares with urgent solutions",
      subheading: "I need a Rescue",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Rectangle_19630_5cf0ff965d.webp",
      skills: [
        "Code Review",
        "Stability",
        "System Grades and Migrations",
        "Consulting and Strategy",
      ],
    },
  ]

  return (
    <React.Fragment>
      <div className={styles.premiumBg}>
        <div className={styles.teamWork}>
          <Container>
            <p className={styles.ser}>
              Pick Your <span>Case</span>
            </p>
            <p className={styles.mainHeading}>
              Backing You Up At Every Stage Of Your Progress
            </p>
            <Row>
              <Slider
                {...settings}
                className={`${styles.SliderWidth} sliderECom`}
                ref={slide1}
              >
                {data.map((e, i) => (
                  <Col xs={12} key={i}>
                    <div className={styles.card}>
                      <img
                        src={e.image1}
                        decoding="async"
                        loading="lazy"
                        alt="slide image"
                      />
                      <p className={styles.subheading}>{e.subheading}</p>
                      <p className={styles.heading}>{e.heading}</p>
                      {e.skills.map((skill, eIdx) => (
                        <p className={styles.skills} key={eIdx}>
                          <img
                            src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8f39253e0a.png"
                            className={styles.check}
                            alt="check icon"
                          />
                          {skill}
                        </p>
                      ))}
                      <Link to="/contact-us/" className={styles.btn}>
                        Explore More
                        <img
                          src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                          decoding="async"
                          loading="lazy"
                          alt="explore icon"
                        />
                      </Link>
                    </div>
                  </Col>
                ))}
              </Slider>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Boost
