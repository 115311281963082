// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--2dc10";
export var backgroundVideo = "Banner-module--backgroundVideo--e45e4";
export var bannerCon = "Banner-module--bannerCon--7b46a";
export var bannerHeading = "Banner-module--bannerHeading--8a291";
export var btn = "Banner-module--btn--73621";
export var dec = "Banner-module--dec--94c62";
export var fintech = "Banner-module--fintech--c27a4";
export var freshprepHeading = "Banner-module--freshprepHeading--535f9";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--d8cf0";
export var ser = "Banner-module--ser--1cf64";