import React from "react"
import * as styles from "./Experience.module.scss"
import Container from "react-bootstrap/Container"
import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import ExperienceSlider from "./ExperienceSlider"

// const data = [
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/fastest_growing_app_development_company_2021_58dd350b2d.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/vr_agencies_c74d51d4d1.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_software_developers_2020_8bccf52025.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_web_development_companies_7a77af654f.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_software_development_companies_409b1f56c7.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_mobile_app_developers_2021_27b906093c.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_web_development_company_0ce429afcb.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/featured_on_upcity_a631ceabf1.png",
//   },
//   {
//     logos:
//       "https://invozone-backend.s3.us-east-1.amazonaws.com/top_ecommerce_development_company_b1062697c7.png",
//   },
// ]

const data2 = [
  {
    heading: "Success",
    description: "Sculpting success stories from raw ideas.",
  },
  {
    heading: "Solutions",
    description: "Creating web app solutions that resonate with your vision.",
  },
  {
    heading: "Opportunities",
    description: "Turning challenges into opportunities.",
  },
  {
    heading: "Creativity",
    description: "Merging creativity with expertise for awesome results.",
  },
  {
    heading: "Experience",
    description: "Translating complex ideas into seamless digital experiences.",
  },
  {
    heading: "Innvoations",
    description: "Building tomorrow's innovations with today's experience.",
  },
]

const TrustInvoZone = ({ strapiData }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 8,
    speed: 500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <div className={styles.Trust}>
      <React.Fragment>
        <Container>
          <Row className="align-items-center">
            <Col lg={6}>
              <p className={styles.ser}>Services</p>
              <h2 className={styles.heading}>
                How We Use Our Experience To Meet Your Needs
              </h2>
              <p className={styles.description}>
                Being in the industry for many years, now we can say that
                product development isn’t just as easy as it seems. It's a
                complete evolution.
              </p>
            </Col>
            <Col lg={6}>
              <Row>
                {data2?.map((e, i) => (
                  <Col lg={6}>
                    <div className={styles.success}>
                      <img
                        src={
                          "https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8e20c0afd7.png"
                        }
                        alt="check"
                        decoding="async"
                        loading="lazy"
                        className={styles.check}
                      />
                      <div key={i} className={styles.portfoliosLogos}>
                        <h3>{e?.heading}</h3>
                        <p className={styles.dec}>{e?.description}</p>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              {/* <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Group_1000007406_a19f8850db.svg"
                className={styles.overlay}
                decoding="async"
                loading="lazy"
              /> */}
            </Col>
            <Col lg={12} md={12} sm={12} xs={12}>
              <ExperienceSlider />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  )
}

export default TrustInvoZone
