import React, { useState } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Progress.module.scss"
import { Link } from "gatsby"

export default function Stories({ strapiData }) {
  const data = [
    {
      name: "Fintech",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Fintech_2f57d52eb9.webp",
      button: "/fintech/",
      button2: "Explore More in Fintech",
      skills: [
        "Accounting information systems",
        "Block-chain based fintech apps",
        "Financial applications development",
        "AI-enabled fintech applications",
      ],
    },
    {
      name: "E-commerce",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Ecommerce_460a89d1eb.webp",
      button: "/e-commerce/",
      button2: "Explore More in E-commerce",
      skills: [
        "Magneto e-commerce applications",
        "B2B e-commerce development",
        "Shopify e-commerce development",
        "IoT-powered e-commerce operations",
        "Product information management ",
      ],
    },
    {
      name: "Healthcare",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Healthcare_fb99f0772e.webp",
      button: "/healthcare/",
      button2: "Explore More in Healthcare",
      skills: [
        "Healthcare application development",
        "Telemedicine software development",
        "IoT healthcare software",
        "Health tracking applications",
        "EMR/EHR software development",
      ],
    },
    {
      name: "Food & Grocery",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Food_and_Grocery_80a304debe.webp",
      button: "/food-groceries/",
      button2: "Explore More in Food & Grocery",
      skills: [
        "Food delivery application development",
        "Grocery store software",
        "Online food ordering application",
        "Restaurant delivery application",
        "Restaurant management software",
      ],
    },
    {
      name: "Education",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Education_6a8002028d.webp",
      button: "/education/",
      button2: "Explore More in Education",
      skills: [
        "Learning management systems",
        "e-Learning application development",
        "Educational platform development",
        "Software for corporate training",
        "Virtual reality educational software",
      ],
    },
    {
      name: "Travel & Tourism",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Travel_and_Tourism_ba6f871cd3.webp",
      button: "/travel-tourism/",
      button2: "Explore More in Travel & Tourism",
      skills: [
        "On-demand travel applications",
        "AR and VR-based travel applications",
        "E-booking and ticketing systems",
        "Transportation information applications",
        "Custom travel ERP solutions",
      ],
    },
    {
      name: "Real Estate",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/Real_Estate_2e54bd437e.webp",
      button: "/contact-us/",
      button2: "Explore More in Estate",
      skills: [
        "Real estate CRM development ",
        "Cloud-based CRM",
        "Location-based services",
        "Salesforce automation services",
        "Automation of mundane tasks",
      ],
    },
    {
      name: "On-demand",
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/On_demand_f9b84b5748.webp",
      button: "/on-demand-services/",
      button2: "Explore More in On-demand",
      skills: [
        "AI-fueled solutions",
        "Push notification systems",
        "Advanced Booking systems",
        "Easy Payment integrations",
        "Effective customer care",
      ],
    },
  ]

  const [activeStory, setActiveStory] = useState(data[0])

  const handleVideoIconClick = () => {
    setIsModalOpen(true)
  }

  return (
    <>
      <div className={styles.section}>
        <Container>
          <p className={styles.ser}>Industries</p>
          <h2 className={styles.heading}>
            Our Software Development Expertise Spans Across Multiple Domains
          </h2>
          <Row className={`${styles.idusCard} align-items-center justify-content-center`}>
            <div className={styles.storyCardWrapper}>
              {data?.map(el => (
                <div key={`story_${el.name}`}>
                  <div
                    className={`${styles.storyCard} ${
                      el.name === activeStory.name && styles.active
                    }`}
                    onClick={() => setActiveStory(el)}
                  >
                    <div className={styles.infoContainer}>
                      <p>{el?.name}</p>
                      <img
                        src="https://invozone-backend.s3.us-east-1.amazonaws.com/arrow_809f86693c.svg"
                        decoding="async"
                        loading="lazy"
                        className={styles.arrow}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div></div>
            <div>
              <div
                className={`${styles.columnTopBannerImg2} d-sm-none d-md-block`}
              >
                {data?.map(el => (
                  <div>
                    {el.name === activeStory.name && (
                      <div className={styles.subHeading}>
                        <img src={el?.image1} className={styles.industriesImg} decoding="async" loading="lazy" />
                        <div className={styles.indDec}>
                          <h3>{el?.name}</h3>
                          <p className={styles.dec}>{el?.description}</p>
                          <div className={styles.points}>
                            {el.skills &&
                              el.skills.map((skill, eIdx) => (
                                <div className={styles.arrowPoint}>
                                  <img
                                    src="https://invozone-backend.s3.us-east-1.amazonaws.com/dot_a41563b685.svg"
                                    decoding="async"
                                    loading="lazy"
                                  />
                                  <p className={`${styles.skill}`}>{skill}</p>
                                </div>
                              ))}
                          </div>
                          <Link to={el?.button} className={styles.btn}>
                            {el?.button2}
                            <img
                              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                              decoding="async"
                              loading="lazy"
                            />
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </>
  )
}
