import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Link } from "gatsby"
import "./Backing.scss"
import * as styles from "./Backing2.module.scss"
import { Container } from "react-bootstrap"

gsap.registerPlugin(ScrollTrigger)

const Backing = () => {
  const containerRef = useRef(null)
  const cardsRef = useRef(null)

  useEffect(() => {
    let totalWidth = 0

    if (cardsRef.current) {
      totalWidth = cardsRef.current.scrollWidth - window.innerWidth
    }

    // Horizontal scroll animation for cards
    gsap.to(cardsRef.current, {
      // x: () => -totalWidth + window.innerWidth / 2, // Adjust to move cards appropriately
      x: -totalWidth,
      ease: "none",
      scrollTrigger: {
        trigger: containerRef.current,
        start: "top top",
        end: () => `+=${totalWidth}`,
        scrub: true,
        pin: true,
        anticipatePin: 1,
      },
    })

    return () => {
      ScrollTrigger.killAll()
    }
  }, [])


  // useEffect(() => {
  //   let totalWidth = 0
  
  //   if (cardsRef.current) {
  //     totalWidth = cardsRef.current.scrollWidth - window.innerWidth
  //   }
  
  //   // Horizontal scroll animation for cards
  //   gsap.to(cardsRef.current, {
  //     x: -totalWidth,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: containerRef.current,
  //       start: "top top",
  //       end: () => `+=${totalWidth}`,
  //       scrub: true,
  //       pin: true,
  //       anticipatePin: 1,
  //     },
  //   })
  
  //   // Rotation animations for each card
  //   const cards = cardsRef.current.children
  //   gsap.utils.toArray(cards).forEach((card, index) => {
  //     let rotateDirection = index % 2 === 0 ? 1 : -1 // Alternate rotation direction
  //     gsap.to(card, {
  //       rotation: rotateDirection * 5, // Rotate between -5 and 5 degrees
  //       yoyo: true,
  //       repeat: -1,
  //       duration: 2,
  //       ease: "power1.inOut",
  //       scrollTrigger: {
  //         trigger: containerRef.current,
  //         start: "top top",
  //         end: () => `+=${totalWidth}`,
  //         scrub: true,
  //       },
  //     })
  //   })
  
  //   return () => {
  //     ScrollTrigger.killAll()
  //   }
  // }, [])


  // useEffect(() => {
  //   let totalWidth = 0;
  
  //   if (cardsRef.current) {
  //     totalWidth = cardsRef.current.scrollWidth - window.innerWidth;
  //   }
  
  //   // Horizontal scroll animation for cards
  //   gsap.to(cardsRef.current, {
  //     x: -totalWidth,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: containerRef.current,
  //       start: "top top",
  //       end: () => `+=${totalWidth}`,
  //       scrub: true,
  //       pin: true,
  //       anticipatePin: 1,
  //     },
  //   });
  
  //   // Smooth rotation animations for each card based on scroll progress
  //   const cards = cardsRef.current.children;
  //   gsap.utils.toArray(cards).forEach((card, index) => {
  //     // Calculate rotation based on scroll position
  //     gsap.to(card, {
  //       rotation: () => (index % 2 === 0 ? 5 : -5),
  //       scrollTrigger: {
  //         trigger: containerRef.current,
  //         start: "top top",
  //         end: () => `+=${totalWidth}`,
  //         scrub: true,
  //         onUpdate: self => {
  //           const rotationAmount = (self.progress - 0.5) * 10; // Smooth transition between -5deg and 5deg
  //           card.style.transform = `rotate(${rotationAmount}deg)`;
  //         },
  //       },
  //     });
  //   });
  
  //   return () => {
  //     ScrollTrigger.killAll();
  //   };
  // }, []);
  
  
  // useEffect(() => {
  //   let totalWidth = 0;
  
  //   if (cardsRef.current) {
  //     totalWidth = cardsRef.current.scrollWidth - window.innerWidth;
  //   }
  
  //   // Horizontal scroll animation for cards
  //   gsap.to(cardsRef.current, {
  //     x: -totalWidth,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: containerRef.current,
  //       start: "top top",
  //       end: () => `+=${totalWidth}`,
  //       scrub: true,
  //       pin: true,
  //       anticipatePin: 1,
  //     },
  //   });
  
  //   // Smooth rotation animations for each card based on scroll progress
  //   const cards = cardsRef.current.children;
  //   gsap.utils.toArray(cards).forEach((card, index) => {
  //     // Rotation logic: first and third cards rotate -5 to 5, second rotates 5 to -5
  //     const rotateDirection = index % 2 === 0 ? 5 : -5;
  //     gsap.to(card, {
  //       scrollTrigger: {
  //         trigger: containerRef.current,
  //         start: "top top",
  //         end: () => `+=${totalWidth}`,
  //         scrub: true,
  //         onUpdate: (self) => {
  //           const rotationAmount = rotateDirection * (self.progress - 0.5) * 2; // Scaling for smooth rotation
  //           card.style.transform = `rotate(${rotationAmount}deg)`;
  //         },
  //       },
  //     });
  //   });
  
  //   return () => {
  //     ScrollTrigger.killAll();
  //   };
  // }, []);
  



  // useEffect(() => {
  //   let totalWidth = 0;
  
  //   if (cardsRef.current) {
  //     totalWidth = cardsRef.current.scrollWidth - window.innerWidth;
  //   }
  
  //   // Horizontal scroll animation for cards
  //   gsap.to(cardsRef.current, {
  //     x: -totalWidth,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: containerRef.current,
  //       start: "top top",
  //       end: () => `+=${totalWidth}`,
  //       scrub: true,
  //       pin: true,
  //       anticipatePin: 1,
  //     },
  //   });
  
  //   // Smooth rotation animations for each card based on scroll progress
  //   const cards = cardsRef.current.children;
  //   gsap.utils.toArray(cards).forEach((card, index) => {
  //     let rotateDirection = (index % 2 === 0) ? 1 : -1; // Determines rotation direction
  //     gsap.to(card, {
  //       scrollTrigger: {
  //         trigger: containerRef.current,
  //         start: "top top",
  //         end: () => `+=${totalWidth}`,
  //         scrub: true,
  //         onUpdate: (self) => {
  //           const rotationAmount = rotateDirection * (self.progress - 0.5) * 10; // Smooth rotation calculation
  //           card.style.transform = `rotate(${rotationAmount}deg)`;
  //         },
  //       },
  //     });
  //   });
  
  //   return () => {
  //     ScrollTrigger.killAll();
  //   };
  // }, []);
  
  

  const data = [
    {
      heading: "Remarkable ideas need the right team for execution",
      subheading: (
        <>
          I’m A <span className={styles.blueText}>StartUp</span>
        </>
      ),
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/startup_8a2b665761.webp",
      skills: [
        "Validate your idea",
        "Product Development Roadmap",
        "Prototyping",
        "Launch MVP in 3 months",
      ],
    },
    {
      heading: "Get scalable enterprise solutions for your business",
      subheading: (
        <>
          I'm an <span className={styles.blueText}>Enterprise</span>
        </>
      ),
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/enterprise_3e1ec4533a.webp",
      skills: [
        "Digital Transformation",
        "Application & System Reengineering",
        "Enterprise Mobility",
        "Enterprise App Development",
      ],
    },
    {
      heading: "Fixing buggy softwares with urgent solutions",
      subheading: (
        <>
          I need a <span className={styles.blueText}>Rescue</span>
        </>
      ),
      image1:
        "https://invozone-backend.s3.us-east-1.amazonaws.com/rescue_9f1a622c97.webp",
      skills: [
        "Code Review",
        "Stability",
        "System Grades and Migrations",
        "Consulting and Strategy",
      ],
    },
  ]

  return (
    <div className={styles.backing} ref={containerRef}>
      <Container>
        <p className={styles.ser}>Pick Your Case</p>
        <h2 className={styles.mainHeading}>
          Backing You Up At Every Stage Of Your Progress
        </h2>
      </Container>
      <div className={styles.horizontalscrollcards} ref={cardsRef}>
        {data.map((e, i) => (
          <div
            className={
              i === 0
                ? styles.card1
                : i === 1
                ? styles.card2
                : i === 2
                ? styles.card3
                : ""
            }
          >
            <p className={styles.subheading}>{e?.subheading}</p>
            <h3 className={styles.heading}>{e?.heading}</h3>
            {e.skills.map((skill, eIdx) => (
              <p className={styles.skills} key={eIdx}>
                <img
                  src="https://invozone-backend.s3.us-east-1.amazonaws.com/check_3_7_8f39253e0a.png"
                  className={styles.check}
                  alt="check icon"
                />
                {skill}
              </p>
            ))}
            <Link to="/contact-us/" className={styles.btn}>
              Explore More
              <img
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
                decoding="async"
                loading="lazy"
                alt="explore icon"
              />
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Backing
