// extracted by mini-css-extract-plugin
export var arrowPoint = "ProgressMobile-module--arrowPoint--3572d";
export var btn = "ProgressMobile-module--btn--83673";
export var card = "ProgressMobile-module--card--2dde2";
export var cardWrapper = "ProgressMobile-module--cardWrapper--f4e3f";
export var dec = "ProgressMobile-module--dec--f1ba9";
export var faqsNum = "ProgressMobile-module--faqsNum--5fa2e";
export var faqsSub = "ProgressMobile-module--faqsSub--0adb7";
export var gifImg = "ProgressMobile-module--gifImg--5cfe2";
export var head = "ProgressMobile-module--head--253fe";
export var heading = "ProgressMobile-module--heading--14ffe";
export var iconssCard = "ProgressMobile-module--iconssCard--f20b8";
export var quesColor = "ProgressMobile-module--quesColor--702dd";
export var quesColorSelected = "ProgressMobile-module--quesColorSelected--d7d2a";
export var section = "ProgressMobile-module--section--0ffdc";
export var ser = "ProgressMobile-module--ser--3584e";
export var serImg = "ProgressMobile-module--serImg--e14c2";
export var serial = "ProgressMobile-module--serial--84b4f";
export var serial2 = "ProgressMobile-module--serial2--f5b90";
export var techImg = "ProgressMobile-module--techImg--ad3f1";
export var technologyIcon = "ProgressMobile-module--technologyIcon--ee7bd";
export var textColor = "ProgressMobile-module--textColor--913c0";
export var yellow = "ProgressMobile-module--yellow--87bf7";