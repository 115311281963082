// extracted by mini-css-extract-plugin
export var SliderWidth = "BackingMobile-module--SliderWidth--543d9";
export var btn = "BackingMobile-module--btn--0d1e3";
export var card = "BackingMobile-module--card--dee9c";
export var check = "BackingMobile-module--check--3676a";
export var heading = "BackingMobile-module--heading--5fedd";
export var mainHeading = "BackingMobile-module--mainHeading--45a1f";
export var premiumBg = "BackingMobile-module--premiumBg--24830";
export var ser = "BackingMobile-module--ser--53a64";
export var skills = "BackingMobile-module--skills--bbcb9";
export var subheading = "BackingMobile-module--subheading--8c846";
export var teamWork = "BackingMobile-module--teamWork--2167d";